<template>
  <div class="order-card-container mt-5">
    <b-form-radio-group @change="changePaymentSelect" class v-model="selected">
      <b-container>
        <b-row>
          <b-col md="7">
            <b-col class="p-0">
              <div class="mb-4">
                <span class="order-header mb-5">Payment Methods</span>
              </div>
              <div class="d-flex mt-0 mb-0">
                <b-form-radio
                  v-model="$store.state['checkout'].paymentMethodClick"
                  value="CASHFREE"
                  class="mt-3"
                  name="radio-size"
                  size="lg"
                >
                  <!-- <h6 style="margin-top: 6px; font-size: 20px">Cashfree</h6> -->
                </b-form-radio>
                <h6 style="margin-top: 15px; font-size: 20px">Cashfree</h6>
              </div>
            </b-col>
            <b-col class="p-0">
              <div class="d-flex">
                <b-form-radio
                  value="TRONADO"
                  v-model="$store.state['checkout'].paymentMethodClick"
                  class="mt-3"
                  name="radio-size"
                  size="lg"
                >
                  <!-- <h6 style="margin-top: 6px; font-size: 20px">Tronado (Coming soon)</h6> -->
                </b-form-radio>
                <h6 style="margin-top: 15px; font-size: 20px">
                  Tronado (Coming soon)
                </h6>
              </div>
            </b-col>
          </b-col>
          <b-col md="5" class="mt-0">
            <b-container>
              <div class="mb-3">
                <span class="order-header mb-5">Price details</span>
              </div>
              <b-row class="w-200">
                <b-col>
                  <b-card-text class="sub-total">Sub Total:</b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="price-details"
                    >₹ {{ this.finalCheckoutData.data.totalPrice }}</b-card-text
                  >
                </b-col>
              </b-row>
              <hr />
              <b-row class="w-200">
                <b-col>
                  <b-card-text class="total-amount">Total Amount</b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="price-details"
                    >₹ {{ this.finalCheckoutData.data.totalPrice }}</b-card-text
                  >
                </b-col>
              </b-row>
              <hr />
              <b-col>
                <b-card-text class="price-details mt-3 cashfree-text"
                  >Please Pay CASHFREE Only ₹
                  {{ this.finalCheckoutData.data.totalPrice }}</b-card-text
                >
              </b-col>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-form-radio-group>
    <b-container>
      <b-row class="mt-5 mb-3">
        <b-col class="text-center">
          <!-- <template v-if="$store.state['billPayment'].checkoutLoading">
              <b-spinner
                v-for="i in 3"
                :key="i"
                class="mr-1"
                small
                label="Small Spinner"
                type="grow"
              ></b-spinner>
          </template>-->
          <button
            v-if="activatePaymentKey"
            @click="confirmOrderInit"
            type="button"
            class="btn btn-primary btn-lg center"
          >
            <span class="button-text"></span> Confirm Order
          </button>
        </b-col>
      </b-row>
    </b-container>
    <VueCashfree
      :initialiseKey="initialiseKey"
      @onSuccess="onSuccess"
      @onFailure="onFailure"
      :orderToken="orderToken"
      :env="process.env.CASHFREE_ENVIRONMENT"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueCashfree from "vue-cashfree-sdk";
export default {
  name: "payment",
  components: {
    VueCashfree,
  },
  data() {
    return {
      selected: "CASHFREE",
      activatePaymentKey: true,
      initialiseKey: false,
      orderToken: "",
    };
  },
  computed: {
    ...mapState({
      defaultAddress: (state) => state.address.defaultAddress,
      finalCheckoutData: (state) => state.billPayment.finalCheckoutData,
      userInfo: (state) => state.auth.userInfo,
      paymentMethodClick: (state) => state.billPayment.paymentMethodClick,
      checkoutData: (state) => state.checkout.checkoutData,
      productData: (state) => state.billPayment.productData,
      orderId: (state) => state.billPayment.orderId,
    }),
  },
  created() {
    this.$store.state["billPayment"].paymentMethodClick = "CASHFREE";
    if (!this.$store.state["auth"].isLoggedin) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    onFailure(err) {
      console.log(err);
      this.$swal({
        group: "item",
        title:
          "Sorry..we are not able to place your order currently , if money is deducted it will be refund out in between 7 working days.",
        text: "",
        type: "error",
      });
    },
    onSuccess(res) {
      console.log(res);
      this.placeOrderFinal();
      this.orderFinalValiadtion();
    },
    async confirmOrderInit() {
      if (this.paymentMethodClick === "CASHFREE") {
        await this.$store.dispatch("billPayment/getFinalCheckoutData");
        await this.$store.dispatch("address/fetchDeliveryAddress");
        this.initiateCashfree();
      }
    },
    async changePaymentSelect(res) {
      this.selected = res;
      console.log(res);
      await this.$store.dispatch("address/fetchDeliveryAddress");
      await this.$store.dispatch("billPayment/getFinalCheckoutData");
      this.placeOrderFinal();
    },
    async initiateCashfree() {
      if (this.finalCheckoutData?.data?.payment?.signatureWeb) {
        this.orderToken = this.finalCheckoutData?.data?.payment?.signatureWeb;
        this.initialiseKey = false;
        setTimeout(() => {
          this.initialiseKey = true;
        }, 1);
      } else {
        this.initialiseKey = false;
      }
    },

    async placeOrderFinal() {
      let payload = {
        customerDetails: {
          orderAmount: this.finalCheckoutData.data.totalPrice,
          customerName: this.userInfo.authentication.userName,
          customerPhone: this.userInfo.authentication.phone,
          customerEmail: this.userInfo.authentication.email,
        },
        orderDetails: {
          paymentType: this.finalCheckoutData.data.items[0].type,
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: this.finalCheckoutData.data.items
            ? this.finalCheckoutData.data.items
            : [],
        },
        txData: {
          orderId: this.finalCheckoutData.data.payment.orderId,
          referenceId: "",
          txStatus: "",
          txMsg: "",
          txTime: "",
          signature: "",
          PaymentServiceName: "",
        },
        createdAt: this.finalCheckoutData.data.createdAt,
        payments: this.finalCheckoutData.data.payments,
        payment: this.finalCheckoutData.data.payment,
        orderHash: this.finalCheckoutData.data.hash,
        isNew: true,
      };
      await this.$store
        .dispatch("billPayment/createOrderAction", payload)
        .then(async (res) => {
          console.log("order creation", res);
          this.$store.state["billPayment"].checkoutLoading = false;
          this.$swal({
            group: "item",
            title: "Order Creted",
            text: "",
            type: "success",
          });
          this.$router.push({
            name: "order-success",
            params: {
              orderId: res.data?.response?.orderId,
            },
          });
        })
        .catch((err) => {
          this.$store.state["billPayment"].checkoutLoading = false;
          console.log("err", err);
        });
    },
    async orderFinalValiadtion() {
      let payload = {
        customerDetails: {
          orderAmount: this.finalCheckoutData.data.totalPrice,
          customerName: this.userInfo.authentication.userName,
          customerPhone: this.userInfo.authentication.phone,
          customerEmail: this.userInfo.authentication.email,
        },
        orderDetails: {
          paymentType: this.finalCheckoutData.data.items[0].type,
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: this.finalCheckoutData.data.items
            ? this.finalCheckoutData.data.items
            : [],
        },
        txData: {
          orderId: this.finalCheckoutData.data.payment.orderId,
          referenceId: "",
          txStatus: "",
          txMsg: "",
          txTime: "",
          signature: "",
          PaymentServiceName: "",
        },
        createdAt: this.finalCheckoutData.data.createdAt,
        payments: this.finalCheckoutData.data.payments,
        payment: this.finalCheckoutData.data.payment,
        orderHash: this.finalCheckoutData.data.hash,
        //isNew: true,
      };
      await this.$store
        .dispatch("billPayment/createOrdervalidateAction", payload)
        .then(async (res) => {
          console.log("order creation", res);
        });
    },
    continuePayment() {
      this.activatePaymentKey = true;
    },
    back() {
      history.back();
    },
    async mounted() {
      await this.$store.dispatch("address/fetchDeliveryAddress");
      await this.$store.dispatch("billPayment/getFinalCheckoutData");
    },
  },
};
</script>
<style scoped>
.cashfree-text {
  color: #fd7e14 !important;
  font-size: 20px !important;
}
.order-left {
  left: 150px;
}
.order-card-container {
  width: 80%;
  margin-left: 130px;
}
.total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 39px;
  color: #000000;
}
.sub-total {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
  color: #000000;
}
.price-details {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 39px;
  color: #000000;
}
.order-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 49px; */
  color: #000000;
}
.order-header-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 89px;
  color: #000000;
}
.list-group {
  margin-top: 15px;
  max-height: 540px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
